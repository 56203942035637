import { bscTestnet, polygon } from "viem/chains";
import {
    ContractDeploymentObjectType,
    SupportedTokensParams,
    SupportedTokenStructParams
} from "./types";

export type ContractObjectType = {
    [key: number]: {
        deploymentDetails: ContractDeploymentObjectType;
    };
};

export type UniswapV2ContractAdddressByChain = {
    [key: number]: {
        name: string;
        routerV2: string;
    };
};

export const supportedTokenParams: SupportedTokensParams = {
    [polygon.id]: [
        {
            name: "Native",
            symbol: "Native",
            address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            isNative: true,
            aggregator: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0"
        },
        {
            name: "Fasst",
            symbol: "FAS",
            address: "0xCc0f4a77E377aDd02463091ed0634A8766E6323e",
            isNative: false,
            aggregator: "0x0000000000000000000000000000000000000000"
        },

        {
            name: "Dai Stablecoin",
            symbol: "DAI",
            address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
            isNative: false,
            aggregator: "0x4746dec9e833a82ec7c2c1356372ccf2cfcd2f3d"
        },
        {
            name: "Tether",
            symbol: "USDT",
            address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
            isNative: false,
            aggregator: "0x0A6513e40db6EB1b165753AD52E80663aeA50545"
        },
        {
            name: "USD Coin",
            symbol: "USDC",
            address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
            isNative: false,
            aggregator: "0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7"
        },
        {
            name: "ChainLink",
            symbol: "LINK",
            address: "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
            isNative: false,
            aggregator: "0xd9FFdb71EbE7496cC440152d43986Aae0AB76665"
        }
    ],
    [bscTestnet.id]: [
        {
            name: "Native",
            symbol: "Native",
            address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            isNative: true,
            aggregator: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526"
        },
        {
            name: "Fasst",
            symbol: "FAS",
            address: "0xa5289CBE8bcD239Fc94C58c2652250AC7f4C7Ef7",
            isNative: false,
            aggregator: "0x0000000000000000000000000000000000000000"
        },
        {
            name: "Tether",
            symbol: "USDT",
            address: "0x13Cdd5bdFf651f894B3AD4cfF3aF71D1609f83ef",
            isNative: false,
            aggregator: "0xEca2605f0BCF2BA5966372C99837b1F182d3D620"
        },
        {
            name: "Tether",
            symbol: "USDT",
            address: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
            isNative: false,
            aggregator: "0xEca2605f0BCF2BA5966372C99837b1F182d3D620"
        },
        {
            name: "tBNB",
            symbol: "tBNB",
            address: "0x8833fE813D526b5DA15C5713695861e06820A37D",
            isNative: false,
            aggregator: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526"
        }
    ]
};

export const nativeTokenStructParams: SupportedTokenStructParams = {
    [polygon.id]: [
        true,
        true,
        "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
        "Native",
        "Native",
        18
    ],
    [bscTestnet.id]: [
        true,
        false,
        "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526",
        "Native",
        "Native",
        18
    ]
};

export const projectTokenStructParams: SupportedTokenStructParams = {
    [polygon.id]: [
        true,
        false,
        "0xCc0f4a77E377aDd02463091ed0634A8766E6323e",
        "0x0000000000000000000000000000000000000000",
        "Fasst",
        "FAS",
        18
    ],
    [bscTestnet.id]: [
        true,
        false,
        "0xA42222D92e6636BAD1d8749C617F932d959BC30C",
        "0x0000000000000000000000000000000000000000",
        "Fasst",
        "FAS",
        18
    ]
};

export const stableTokenStructParams: SupportedTokenStructParams = {
    [polygon.id]: [
        true,
        false,
        "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        "0x0A6513e40db6EB1b165753AD52E80663aeA50545",
        "Tether",
        "USDT",
        6
    ],
    [bscTestnet.id]: [
        true,
        false,
        "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
        "0xEca2605f0BCF2BA5966372C99837b1F182d3D620",
        "Tether",
        "USDT",
        18
    ]
};

export const UniswapV2RouterAddress: UniswapV2ContractAdddressByChain = {
    [polygon.id]: {
        name: "UniswapV2",
        routerV2: "0xedf6066a2b290C185783862C7F4776A2C8077AD1"
    },
    [bscTestnet.id]: {
        name: "PancakeSwap",
        routerV2: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1"
    }
};

export const investmentPlansArray = [
    [1, 6, 7776000, 1],
    [2, 18, 15552000, 1],
    [3, 36, 23328000, 1],
    [4, 60, 31104000, 1],
    [5, 108, 46656000, 1],
    [6, 168, 62208000, 1]
];

export type StructInvestmentPlanObjectStructOutput = {
    duration: bigint;
    per: bigint;
    maxLimitMul: bigint;
    planId: bigint;
    minContribution: number;
};

export const investmentPlansObjectArray: StructInvestmentPlanObjectStructOutput[] =
    [
        {
            duration: BigInt(86400 * 3 * 30),
            per: BigInt(6),
            maxLimitMul: BigInt(1),
            planId: BigInt(1),
            minContribution: 11
        },
        {
            duration: BigInt(86400 * 6 * 30),
            per: BigInt(18),
            maxLimitMul: BigInt(1),
            planId: BigInt(2),
            minContribution: 25
        },
        {
            duration: BigInt(86400 * 9 * 30),
            per: BigInt(36),
            maxLimitMul: BigInt(1),
            planId: BigInt(3),
            minContribution: 50
        },
        {
            duration: BigInt(86400 * 12 * 30),
            per: BigInt(60),
            maxLimitMul: BigInt(1),
            planId: BigInt(4),
            minContribution: 100
        },
        {
            duration: BigInt(86400 * 18 * 30),
            per: BigInt(108),
            maxLimitMul: BigInt(1),
            planId: BigInt(5),
            minContribution: 500
        },
        {
            duration: BigInt(86400 * 24 * 30),
            per: BigInt(168),
            maxLimitMul: BigInt(1),
            planId: BigInt(6),
            minContribution: 1000
        }
    ];

export const adminAddress = [
    "0x54ca0b98cfED3EC893FFc6Ed6efbe1f2FCF02E87",
    "0x5B8D7389aB26c801af5aF40920B54895B9D0edfb",
    "0x1f03e4CA5C3Fe8c2A407525B0e867aA4c9b878f1",
    "0xfbc93296d9d8be15b9a98cf3ede8bc780823a499"
];

export const referralRates = [
    [[5, 100], 0],
    [[2, 100], 0],
    [[1, 100], 0],
    [[1, 100], 0],
    [[1, 100], 0]
];
